import React from "react"
import styled from "styled-components"

import { navigate, graphql } from "gatsby"
import { Section } from "../../components/sections/sections.js"
import { InPageNav } from "../../components/navbar.js"
import { ValuePropLeft } from "../../components/sections/valuePropSection.js"
import Layout from "../../components/layout.js"
import {
  SectionTitle,
  SectionDescription,
} from "../../components/typography.js"
import { TeamCard } from "../../components/molecules/cards.js"

import ScrumSvg from "../../images/Scrum_board.svg"
import Coding from "../../images/hacker_mind.svg"
import Social from "../../images/social_networking.svg"
// import Sohaib from "../../images/myPicture.jpeg"
// import Sophia from "../../images/sophia.png"
// import Humayun from "../../images/humayun.png"

const St = styled(SectionTitle)`
  width: 90%;
  margin: 0 auto;
  font-size: 70px;
`
const Sd = styled(SectionDescription)`
  margin: 50px auto;
  width: 90%;
  font-size: 22px;
  text-align: justify;
  text-align-last: center;
`
const Span = styled.span`
  color: #f50057;
`

const Instructors = ({ data, location }) => {
  const RedirectToPage = dest => {
    navigate(dest)
  }
  return (
    <Layout>
      <InPageNav
        currentPath={location.pathname}
        pageTitle="Our Team"
        navItems={[
          { link: "/instructors", label: "Overview" },
          { link: "/instructors/humayun_ahmed", label: "Humayun" },
          { link: "/instructors/sophia_glisch", label: "Sophia" },
          { link: "/instructors/sohaib_ahmed", label: "Sohaib" },
        ]}
        clickCallback={RedirectToPage}
      />
      <Section>
        <St center={"center"}>
          <Span>Heyyyy </Span> &nbsp;
          <span
            style={{
              transform: "rotate(110deg) scale(1.41)",
              display: "inline-block",
              position: "relative",
              top: "24px",
              left: "30px",
              fontWeight: "300",
              fontSize: "90px",
            }}
          >
            :)
          </span>
        </St>

        <Sd>
          <Span>We can’t wait to maybe “meet” you via call or in writing,</Span>{" "}
          but for now, here’s a little something about us! 99point9 is the
          brainchild of the three of us—Humayun, Sohaib, and Sophia. And
          incidentally, we’re the same people you’re going to be working (read:
          and chilling) with if you do decide you’d like a little help. Though
          we each do slightly different things at 99p9, the most important thing
          stays the same in each of our cases: we’re all in this together. And
          no matter how tough it gets, it’s also gonna be fun.
        </Sd>
      </Section>

      <Section style={{ paddingBottom: "0px", paddingTop: "0px" }}>
        <div style={{ display: "flex", flexFlow: "row wrap" }}>
          {!data ? null : (
            <>
              <TeamCard
                fluid={data.sohaibImage.childImageSharp.fluid}
                link={"/instructors/sohaib_ahmed"}
                name={"Sohaib"}
                alt="Sohaib's picture"
                descrip={
                  "Sohaib makes sure that students falls into line when needed. He's also the 'tech. guy' and oversees 99point9's digital end."
                }
              />
              <TeamCard
                fluid={data.sophiaImage.childImageSharp.fluid}
                name={"Sophia"}
                link={"/instructors/sohaib_ahmed"}
                alt="Sophia's picture"
                descrip={
                  "Sohaib makes sure that students falls into line when needed. He's also the 'tech. guy' and oversees 99point9's digital end."
                }
              />
              <TeamCard
                fluid={data.humayunImage.childImageSharp.fluid}
                name={"Humayun"}
                link={"/instructors/sohaib_ahmed"}
                alt="Humayun's picture"
                descrip={
                  "Sohaib makes sure that students falls into line when needed. He's also the 'tech. guy' and oversees 99point9's digital end."
                }
              />
            </>
          )}
        </div>
      </Section>
      <ValuePropLeft
        caption={" "}
        title={[`Our team is `, <Span>caring</Span>]}
        description={`One of the key features that define people at 99point9 is how much they care about their students, and the distance they're willing to travel in order to see them succeed. They don't aim for the bare minimum, or the "passable"; they want their students - no matter who they are, where they're from, or what they want to achieve - to be the best at they want to do.`}
        source={ScrumSvg}
      />
      <ValuePropLeft
        caption={" "}
        title={[`Our team is  `, <Span>visionary</Span>]}
        description={
          "Day in and out, our team obsesses about the future. We're continiously exploring the untapped potential of learning, the role of technology in it, and what this means for the future of our students. We're also proud to say that, we're currently on the brink of some potentially industry-changing innovation - so, stay tuned!"
        }
        source={Coding}
      />
      <ValuePropLeft
        caption={" "}
        title={[`Our team `, <Span>gets results</Span>]}
        description={`With over 40 years of combined teaching experience, our team is somewhat used to managing students, teaching difficult concepts with ease, and getting results. Our instructors are tuned to their students, build long lasting friendships with them, and hence change their learning-attitude via non-mainstream teaching means, achieving unparalleled standardized-test results and admissions success rates.`}
        source={Social}
      />
    </Layout>
  )
}

export default Instructors

export const query = graphql`
  query {
    humayunImage: file(relativePath: { eq: "humayun.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sophiaImage: file(relativePath: { eq: "sophia.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sohaibImage: file(relativePath: { eq: "myPicture.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
